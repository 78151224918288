export const auth = {
  LOGIN: "LOGIN",
  VARIFY_OTP: "VARIFY_OTP",
  CONTACT_US: "CONTACT_US",
  FCM_NOTIFICATION: "FCM_NOTIFICATION",
  UNDER_MAINTENANCE: "UNDER_MAINTENANCE",
};

export const customer = {
  CUSTOMER_DISTRIBUTOR: "CUSTOMER_DISTRIBUTOR",
  CUSTOMER_ADD_DISTRIBUTOR: "CUSTOMER_ADD_DISTRIBUTOR",
  CUSTOMER_EDIT_DISTRIBUTOR: "CUSTOMER_EDIT_DISTRIBUTOR",
  CUSTOMER_DETAILS: "CUSTOMER_DETAILS",
  ADD_REATAILER: "ADD_REATAILER",
  STAFF_ASSIGN_MAPPING: "STAFF_ASSIGN_MAPPING",
  DELETE_CUSTOMER: "DELETE_CUSTOMER",
  SEARCH_STAFF_ASSIGN: "SEARCH_STAFF_ASSIGN",
  CUSTOMER_LEVEL_DISTRIBUTOR_LIST: "CUSTOMER_LEVEL_DISTRIBUTOR_LIST",
  SEARCH_CUSTOMER_CATEGORY_ASSIGN: "SEARCH_CUSTOMER_CATEGORY_ASSIGN",
};

export const pan = {
  GET_PAN_DETAILS: "GET_PAN_DETAILS",
};

export const dashboard = {
  DASHBOARD: "DASHBOARD",
};

//overview
export const dashboardOverview = {
  DASHBOARD_OVERVIEW: "DASHBOARD_OVERVIEW",
};

//overview
export const chart = {
  ANALYTICS_CHART: "ANALYTICS_CHART",
};

//customer analytics
export const customeranalytics = {
  CUSTOMER_ANALYTICS: "CUSTOMER_ANALYTICS",
};

//product analytics
export const productanalytics = {
  PRODUCT_ANALYTICS: "PRODUCT_ANALYTICS",
};

//staff analytics
export const staffanalytics = {
  STAFF_ANALYTICS: "STAFF_ANALYTICS",
};

//category analytics
export const categoryanalytics = {
  CATEGORY_ANALYTICS: "CATEGORY_ANALYTICS",
};

export const expense = {
  SET_REPORT_EXPENSE: "SET_REPORT_EXPENSE",
  SET_CREATE_REPORT_EXPENSE: "SET_CREATE_REPORT_EXPENSE",
};

//order
export const order = {
  SET_ORDER: "SET_ORDER",
  SET_CREATE_REPORT_ORDER: "SET_CREATE_REPORT_ORDER",
  SET_REPORT_ORDER: "SET_REPORT_ORDER",
  SET_ORDER_VIEW: "SET_ORDER_VIEW",
  GET_ORDER_DETAILS_BYID: " GET_ORDER_DETAILS_BYID",
  UPDATE_STATUS: "UPDATE_STATUS",
  DISPATCH_ORDER: "DISPATCH_ORDER",
  DISPATCH_HISTORY: "DISPATCH_HISTORY",
  LR_UPDATE_ORDER: "LR_UPDATE_ORDER",
  DELETE_ORDER: "DELETE_ORDER",
  SUPPORT_REPORT_FIELD: "SUPPORT_REPORT_FIELD",
};

//payment
export const payment = {
  SET_PAYMENT: "SET_PAYMENT",
  ADD_PAYMENT: "ADD_PAYMENT",
  PAYMENT_RECORD_BY_ID: "PAYMENT_RECORD_BY_ID",
  DELETE_PAYMENT: "DELETE_PAYMENT",
  PAYMENT_UPDATE_STATUS: "PAYMENT_UPDATE_STATUS",
  GET_PAYMENT_BY_ID: "GET_PAYMENT_BY_ID",
};

//staff
export const staff = {
  STAFF: "STAFF",
  STAFF_LIST: "STAFF_LIST",
  STAFF_ADD: "STAFF_ADD",
  STAFF_EDIT: "STAFF_EDIT",
  STAFF_DELETE: "STAFF_DELETE",
  STAFF_ORDER: "STAFF_ORDER",
  STAFF_PAYMENT: "STAFF_PAYMENT",
  STAFF_DETAILS: "STAFF_DETAILS",
  SEARCH_CUSTOMER_ASSIGN: "SEARCH_CUSTOMER_ASSIGN",
  STAFF_DETAILS_BY_ID: "STAFF_DETAILS_BY_ID",
  STAFF_CUSTOMER_ASSIGNED: "STAFF_CUSTOMER_ASSIGNED",
};

//products
export const product = {
  PRODUCT: "PRODUCT",
  SEARCH_PRODUCTS: "SEARCH_PRODUCTS",
  ADD_PRODUCT: "ADD_PRODUCT",
  DELETE_PRODUCT: "DELETE_PRODUCT",
  GET_PRODUCT: "GET_PRODUCT",
};

//product category
export const productCategory = {
  SET_PRODUCT_CATEGORY: "SET_PRODUCT_CATEGORY",
  CREATE_PRODUCT_CATEGORY: "CREATE_PRODUCT_CATEGORY",
  SET_EDIT_PRODUCT_CATEGORY: "SET_EDIT_PRODUCT_CATEGORY",
  SET_PRODUCT_CATEGORY_DETAILS: "SET_PRODUCT_CATEGORY_DETAILS",
  DELETE_PRODUCT_CATEGORY: "DELETE_PRODUCT_CATEGORY",
};

//customer category
export const customerCategory = {
  SET_CUSTOMER_CATEGORY: "SET_CUSTOMER_CATEGORY",
  SET_EDIT_CUSTOMER_CATEGORY: "SET_EDIT_CUSTOMER_CATEGORY",
};

//report payment
export const reportPayment = {
  SET_REPORT_PAYMENT: "SET_REPORT_PAYMENT",
  SET_CREATE_REPORT_PAYMENT: "SET_CREATE_REPORT_PAYMENT",
};

//report product
export const reportProduct = {
  SET_REPORT_PRODUCT: "SET_REPORT_PRODUCT",
  SET_CREATE_REPORT_PRODUCT: "SET_CREATE_REPORT_PRODUCT",
};

//checkout
export const checkout = {
  ADDRESS_LIST: "ADDRESS_LIST",
  ADDRESS_DETAILS: "ADDRESS_DETAILS",
  UPDATE_NEW_ADDRESS: "UPDATE_NEW_ADDRESS",
  UPDATE_OLD_ADDRESS: "UPDATE_OLD_ADDRESS",
  SUBMIT_ORDER: "SUBMIT_ORDER",
  WHATSAPP_REQUIRED: "WHATSAPP_REQUIRED",
  UPDATE_ORDER: "UPDATE_ORDER",
};

//bulk-product-csv-uploading"
export const bulkUploadingCsv = {
  BULK_UPLOADING_CSV: "BULK_UPLOADING_CSV",
  BULK_NEW_UPLOADING_CSV: "BULK_NEW_UPLOADING_CSV",
};

//preferences
export const preferences = {
  GET_PREFERENCES: "GET_PREFERENCES",
  ADD_PREFERENCES: "ADD_PREFERENCES",
  GET_STAFF_PREFERENCES: "GET_STAFF_PREFERENCES",
  ADD_STAFF_PREFERENCES: "ADD_STAFF_PREFERENCES",
};
//role-permission
export const rolesPermission = {
  SET_STAFF_ROLES_PERMISSION: "SET_STAFF_ROLES_PERMISSION",
  SET_PERMISSION: "SET_PERMISSION",
  SET_STAFF_ROLE_ADD: "SET_STAFF_ROLE_ADD",
  SET_STAFF_ROLE_EDIT: "SET_STAFF_ROLE_EDIT",
  SET_STAFF_ROLE_DELETE: "SET_STAFF_ROLE_DELETE",
};

//bulk-product-csv-uploading"
export const productUnit = {
  SET_PRODUCT_UNIT: "SET_PRODUCT_UNIT",
  SET_PRODUCT_UNIT_ADD: "SET_PRODUCT_UNIT_ADD",
  SET_PRODUCT_UNIT_DELETE: "SET_PRODUCT_UNIT_DELETE",
};

//profile details"
export const profileDetails = {
  SET_PROFILE_DETAILS: "SET_PROFILE_DETAILS",
  SET_EDIT_PROFILE_DETAILS: "SET_EDIT_PROFILE_DETAILS",
  SET_USER_PROFILE_DETAILS: "SET_USER_PROFILE_DETAILS",
  SET_EDIT_USER_PROFILE_DETAILS: "SET_EDIT_USER_PROFILE_DETAILS",
  SET_STAFF_PROFILE_DETAILS: "SET_STAFF_PROFILE_DETAILS",
  SET_EDIT_STAFF_PROFILE_DETAILS: "SET_EDIT_STAFF_PROFILE_DETAILS",
};

// gst
export const gst = {
  GET_GST_DETAILS: "GET_GST_DETAILS",
};

// lead management
export const lead = {
  CREATE_LEAD: "CREATE_LEAD",
  UPDATE_LEAD: "UPDATE_LEAD",
  SEARCH_LEAD: "SEARCH_LEAD",
  MOBLIE_NUMBER_CHECK: "MOBLIE_NUMBER_CHECK",
  SINGLE_LEAD_DATA: "SINGLE_LEAD_DATA",

  LEAD_REPORT: "LEAD_REPORT",
};
// Lead-category
export const leadCategory = {
  LEAD_CATEGORY_LIST: "LEAD_CATEGORY_LIST",
  ADD_LEAD_CATEGORY: "ADD_LEAD_CATEGORY",
  LEAD_CATEGORY_DETAILS: "LEAD_CATEGORY_DETAILS",
  UPDATE_LEAD_CATEGORY: "UPDATE_LEAD_CATEGORY",
  DELETE_LEAD_CATEGORY: "DELETE_LEAD_CATEGORY",
  SEARCH_LEAD_CATEGORY: "SEARCH_LEAD_CATEGORY",
};
//reibursement
export const reimbursement = {
  GET_REIMBURSEMENT_TRACKER: "GET_REIBURSEMENT_TRACKER",
  REIBURSEMENT_TRACKER_ADD: "REIBURSEMENT_TRACKER_ADD",
  REIBURSEMENT_TRACKER_EDIT: "REIBURSEMENT_TRACKER_EDIT",
  GET_REIBURSEMENT_LIST: "GET_REIBURSEMENT_LIST",
  REIBURSEMENT_ITEM_ADD: "REIBURSEMENT_ITEM_ADD",
  GET_REIBURSEMENT_ITEM_DETAILS: "GET_REIBURSEMENT_ITEM_DETAILS",
  REIBURSEMENT_ITEM_EDIT: "REIBURSEMENT_ITEM_EDIT",
  REIBURSEMENT_DELETE: "REIBURSEMENT_DELETE",
  REIBURSEMENT_ITEM_DELETE: "REIBURSEMENT_ITEM_DELETE",
  GET_REIMBURSEMENT_TRACKER_DETAILS: "GET_REIMBURSEMENT_TRACKER_DETAILS",
};

//reimbursement
export const ApprovalReimbursement = {
  GET_APPROVAL_REIMBURSEMENT_TRACKER: "GET_APPROVAL_REIMBURSEMENT_TRACKER",
  APPROVED_REIMBURSEMENT_TRACKER: "APPROVED_REIMBURSEMENT_TRACKER",
};

//Feedback and activity
export const feedbackAndActivity = {
  GET_CUSTOMER_ACTIVITY: "GET_CUSTOMER_ACTIVITY",
  ADD_CUSTOMER_ACTIVITY: "ADD_CUSTOMER_ACTIVITY",
  EDIT_CUSTOMER_ACTIVITY: "EDIT_CUSTOMER_ACTIVITY",
  GET_STAFF_ACTIVITY: "GET_STAFF_ACTIVITY",
  GET_TEAM_ACTIVITY: "GET_TEAM_ACTIVITY",
  GET_MY_ACTIVITY: "GET_MY_ACTIVITY",
  GET_FEEDBACK_ACTIVITY: "GET_FEEDBACK_ACTIVITY",

  CHECK_IN: "CHECK_IN",
};

export const pricingGroup = {
  GET_PRICING_GROUP: "GET_PRICING_GROUP",
};

// Push notifications
export const notifications = {
  GET_NOTIFICATIONS: "GET_NOTIFICATIONS",
  UPDATE_NOTIFICATIONS: "UPDATE_NOTIFICATIONS",
};

// Attendance
export const attendance = {
  GET_ATTENDANCE: "GET_ATTENDANCE",
  UPDATE_ATTENDANCE: "UPDATE_ATTENDANCE",
  DELETE_ATTENDANCE: "DELETE_ATTENDANCE",
  SET_REPORT_ATTENDANCE: "SET_REPORT_ATTENDANCE",
  SET_CREATE_REPORT_ATTENDANCE: "SET_CREATE_REPORT_ATTENDANCE",
};

// Storefront
export const storefront = {};

// Goal
export const goal = {
  GET_GOAL_TEMPLATE: "GET_GOAL_TEMPLATE",
  CREATE_GOAL_TEMPLATE: "CREATE_GOAL_TEMPLATE",
  GET_GOAL_BY_ID: "GET_GOAL_BY_ID",
  UPDATE_GOAL_TEMPLATE: "UPDATE_GOAL_TEMPLATE",
  DELETE_GOAL: "DELETE_GOAL",
  CUSTOM_ASSIGN_GOAL: "CUSTOM_ASSIGN_GOAL",
  SEARCH_GOAL_TEMPLATE: "SEARCH_GOAL_TEMPLATE",

  GET_USER_TARGET_ID: "GET_USER_TARGET_ID",
  DELETE_USER_TARGET: "DELETE_USER_TARGET",
  GET_SELF_TARGET_DETAILS: "GET_SELF_TARGET_DETAILS",
  UPDATE_STAFF_TARGET: "UPDATE_STAFF_TARGET",

  GET_TEAM_TARGET_LIST: "GET_TEAM_TARGET_LIST",
  GET_TARGET_DETAILS_BY_ID: "GET_TARGET_DETAILS_BY_ID",

  GET_STAFF_GOAL_DETAILS: "GET_STAFF_GOAL_DETAILS",
};

// // Beat
// export const beat = {
//   BEAT_LIST: "BEAT_LIST",
//   BEAT_ADD: "BEAT_ADD",
//   BEAT_EDIT: "BEAT_EDIT",
//   BEAT_DELETE: "BEAT_DELETE",
//   BEAT_DETAILS: "BEAT_DETAILS",
//   BEAT_MAPPING_SERVICE: "BEAT_MAPPING_SERVICE",
//   BEAT_DETAILS_BY_BEAT_PLAN_ID: "BEAT_DETAILS_BY_BEAT_PLAN_ID",
// };

// Beat
export const beatPlan = {
  BEAT_PLAN_APPROVAL: "BEAT_PLAN_APPROVAL",
  EDIT_BEAT_PLAN: "EDIT_BEAT_PLAN",
};

// Customer Type
export const customerType = {
  GET_CUSTOMER_TYPE: "GET_CUSTOMER_TYPE",
  SET_EDIT_CUSTOMER_CATEGORY: "SET_EDIT_CUSTOMER_CATEGORY",
  SET_ADD_CUSTOMER_TYPE: "SET_ADD_CUSTOMER_TYPE",
  SET_EDIT_CUSTOMER_TYPE: "SET_EDIT_CUSTOMER_TYPE",
  DELETE_CUSTOMER_TYPE: "DELETE_CUSTOMER_TYPE",
};

export const adminSetting = {
  CREATE_NEW_ADMIN: "CREATE_NEW_ADMIN",
  VERIFY_ADMIN_OTP: "VERIFY_ADMIN_OTP",
  ADMIN_LIST: "ADMIN_LIST",
  DELETE_ADMIN: "DELETE_ADMIN",
};

export const reportTemplate = {
  GET_REPORT_TEMPLATE_LIST: "GET_REPORT_TEMPLATE_LIST",
  CREATE_TEMPLATE: "CREATE_TEMPLATE",
  GET_REPORT_TEMPLATE: "GET_REPORT_TEMPLATE",
  DELETE_REPORT_TEMPLATE: "DELETE_REPORT_TEMPLATE",
};

// City and state Autofill through the pincode
export const PINCODE_API = "PINCODE_API";

// Reminder on top navbar
export const reminder = {
  LIST_FOLLOW_UP_REMINDER: "LIST_FOLLOW_UP_REMINDER",
  DELETE_FOLLOW_UP_REMINDER: "DELETE_FOLLOW_UP_REMINDER",
};
// Custom Activity Type
export const customActivity = {
  CREATE_ACTIVITY_TYPE: "CREATE_ACTIVITY_TYPE",
  UPDATE_ACTIVITY_TYPE: "UPDATE_ACTIVITY_TYPE",
  ACTIVITY_TYPE_LIST: "ACTIVITY_TYPE_LIST",
  DETAIL_ACTIVITY_TYPE: "DETAIL_ACTIVITY_TYPE",
  DELETE_ACTIVITY_TYPE: "DELETE_ACTIVITY_TYPE",
};
