import {
  Col,
  Row,
  Space,
  Input,
  Table,
  Button,
  Tooltip,
  notification,
  Spin,
} from "antd";
import {
  EditIcon,
  ArrowLeft,
  DeleteOutlineIcon,
} from "../../../../assets/globle";
import "./customTableStyles.css";
import styles from "../styles.module.css";
import CustomSwitch from "./CustomSwitch";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  formatAfterDecimalPlaces,
  toIndianCurrency,
} from "../../../../helpers/convertCurrency";
import {
  getPricingGroupDetails,
  updateProductTelescopePricing,
  getProductsTelescopePricingList,
  deleteProductFromPricingGroup,
} from "../../../../redux/action/pricingGroupAction";
import SelectProductModal from "./SelectProductModal";
import WrapText from "../../../../components/wrapText";
import { InfoCircleOutlined } from "@ant-design/icons";
import { BASE_URL_V2, org_id } from "../../../../config";
import { Customer } from "../../../../assets/navbarImages";
import AdminLayout from "../../../../components/AdminLayout";
import removeIcon from "../../../../assets/mines-button.svg";
import { GeneralModal } from "../../../../components/modals";
import bulkUploadIcon from "../../../../assets/upload-icon.svg";
import { decimalInputValidation } from "../../../../helpers/regex";
import { StaffIcon } from "../../../../assets/navbarImages/report";
import noProductIcon from "../../../../assets/no-product-found.png";
import ListViewModal from "../../../distributor/listsModal/StaffList";
import { PlusIcon as addIcon } from "../../../../assets/settings/index";
import SearchInput from "../../../../components/search-bar/searchInput";
import defaultProductIcon from "../../../../assets/no-photo-available.gif";

const constants = {
  raw_products_list: [],
  total_products: 0,
  routes: {
    LIST: "/web/setting?tab=Pricing Group",
    EDIT_FORM: "/web/setting/add-pricing-group",
    BULK_UPLOAD: "/web/pricing-group/bulk-upload",
  },
};

export default function ManageProductsPricing() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [newProduct, setNewProduct] = useState({});
  const [errorState, setErrorState] = useState({});
  const [productsList, setProductsList] = useState([]);
  const [listViewOptions, setListViewOptions] = useState({});
  const [pricingGroupDetails, setPricingDetails] = useState({});
  const [enableTelescopeForAll, setEnableTelescopeForAll] = useState(false);
  const [toggleDeleteConfirmation, setToggleDeleteConfirmation] =
    useState(false);
  const [isEditAction, setIsEditAction] = useState(false);

  const queryParameters = new URLSearchParams(window.location.search);
  const pgId = queryParameters.get("pg_id") || 0;

  const columns = [
    {
      key: "product",
      title: `Product (${constants.raw_products_list.length})`,
      width: 250,
      render: (text, record) => {
        return (
          <>
            <div className="series" style={{ gap: 10, paddingInline: 10 }}>
              <img
                src={
                  record.display_pic_url ||
                  record?.pics_urls?.[0] ||
                  defaultProductIcon
                }
                alt={record.name}
                className={styles.product_img}
              />
              <div>
                <div style={{ fontSize: 12 }}>{record.code}</div>
                <div style={{ color: "#000000", fontWeight: 600 }}>
                  <WrapText width={150}>{record.name}</WrapText>
                </div>
                <div style={{ fontSize: 12, fontWeight: 600 }}>
                  {toIndianCurrency(record.price, 4)}
                </div>
              </div>
            </div>
            <ProxyColumn {...{ record }} />
          </>
        );
      },
    },
    {
      key: "mrp",
      title: "MRP",
      dataIndex: "target_mrp_price",
      width: 150,
      render: (text, record, prodIndex) => (
        <>
          <PriceInput
            placeholder="Enter MRP"
            suffix={`/ ${record.mrp_unit || record.unit}`}
            readOnly={!record.edit_mode}
            status={errorState?.[prodIndex]?.["mrp"] ? "error" : ""}
            value={text}
            onChange={(v) => {
              record.target_mrp_price = v;
              resetError(errorState, prodIndex);
              updatePricingData();
            }}
          />
          <ProxyColumn {...{ record }} />
        </>
      ),
    },
    {
      key: "telescopic",
      title: "Telescopic",
      dataIndex: "telescope_pricing",
      width: 110,
      render: (text, record) => {
        const isChecked = record.is_enable_telescope_pricing;

        return (
          <>
            <div
              className={styles.nested_cell}
              style={{
                paddingInline: 10,
                ...(record.edit_mode && { background: "#fff" }),
              }}
            >
              <CustomSwitch
                value={isChecked}
                onChange={(v) => {
                  record.is_enable_telescope_pricing = v;
                  handleSwitchChange(v, [record]);
                  if (!v && enableTelescopeForAll) {
                    if (enableTelescopeForAll) setEnableTelescopeForAll(false);
                  }
                  updatePricingData();
                  if (!v) return;
                  let enabledTelescopeCount = 0;
                  productsList.forEach((ele) => {
                    if (ele.is_enable_telescope_pricing)
                      enabledTelescopeCount++;
                  });
                  if (enabledTelescopeCount === productsList.length)
                    setEnableTelescopeForAll(true);
                }}
              />
            </div>
            <ProxyColumn {...{ record }} />
          </>
        );
      },
    },
    {
      key: "qty",
      title: "Min. Order Qty",
      width: 150,
      render: (_, record, prodIndex) =>
        record.telescope_pricing.map(({ qty }, index) =>
          index ? (
            <PriceInput
              placeholder="Enter Min. Order"
              additionalClass={styles.qty_input}
              regexOptions={{
                decimalPlaces: 2,
              }}
              value={qty}
              readOnly={!record.edit_mode}
              onChange={(v) => {
                handlePricing(record, index, "qty", v);
                resetError(errorState, prodIndex);
              }}
              status={
                errorState?.[prodIndex]?.["telescope_pricing"]?.[index]?.["qty"]
                  ? "error"
                  : ""
              }
            />
          ) : (
            <div
              className={styles.nested_cell}
              style={{
                background: "#f4f4f4",
                paddingInline: 10,
                fontWeight: 600,
                color: "#000",
              }}
            >
              1
            </div>
          )
        ),
    },
    {
      key: "price",
      title: () => (
        <div>
          Set Price{" "}
          <Tooltip title="Price can be set by directly inputing the price in Set price or by % discount / markup Input">
            <InfoCircleOutlined />
          </Tooltip>
        </div>
      ),
      width: 150,
      render: (_, record, prodIndex) => (
        <>
          {record.telescope_pricing.map(({ price }, index) => (
            <PriceInput
              placeholder="Enter Price"
              suffix={`/ ${record.unit}`}
              readOnly={!record.edit_mode}
              status={
                errorState?.[prodIndex]?.["telescope_pricing"]?.[index]?.[
                  "price"
                ]
                  ? "error"
                  : ""
              }
              value={price}
              onChange={(v) => {
                handlePricing(record, index, "price", v);
                resetError(errorState, prodIndex);
              }}
            />
          ))}
        </>
      ),
    },
    {
      key: "discount",
      width: 180,
      title: () => (
        <div className={styles.space_between}>
          Set discount (-) / markup (+) by %{" "}
          <Tooltip
            title={`You can set the price by entering the amount in the "Set Price" field or by specifying a percentage in "discount/markup" field.`}
          >
            <InfoCircleOutlined />
          </Tooltip>
        </div>
      ),
      render: (_, record) =>
        record.telescope_pricing.map(({ discount }, index) => (
          <PriceInput
            placeholder="0"
            suffix="%"
            regexOptions={{
              type: "discount",
              restrictNegative: false,
            }}
            readOnly={!record.edit_mode}
            value={discount}
            onChange={(v) => handlePricing(record, index, "discount", v)}
          />
        )),
    },
    {
      key: "add_more",
      title: "Add More",
      width: 150,
      render: (_, record) =>
        record?.edit_mode && (
          <div
            style={{
              ...(record.edit_mode && { background: "#fff" }),
              paddingInline: 10,
            }}
          >
            {record.telescope_pricing.map((_, idx) => (
              <div
                key={`add-${idx}`}
                className={`series ${styles.nested_cell}`}
                style={{
                  gap: 8,
                  justifyContent: "flex-start",
                }}
              >
                {!!idx && (
                  <img
                    className="clickable"
                    src={removeIcon}
                    alt="remove"
                    onClick={() => {
                      record.telescope_pricing.splice(idx, 1);
                      updatePricingData();
                    }}
                  />
                )}
                {record.telescope_pricing.length - 1 === idx && (
                  <img
                    className="clickable"
                    src={addIcon}
                    alt="add"
                    onClick={() => {
                      record.telescope_pricing.push({ qty: "", price: "" });
                      updatePricingData();
                    }}
                  />
                )}
              </div>
            ))}
          </div>
        ),
    },
    {
      key: "action",
      title: "Action",
      width: 140,
      render: (_, record, index) => {
        return {
          props: {
            className: record?.edit_mode ? "action-white-cell" : "",
            style: { placeContent: "flex-start" },
          },
          children: (
            <div style={{ padding: "15px 10px", textAlign: "center" }}>
              {record?.edit_mode ? (
                <Button
                  htmlType="button"
                  className="button_primary"
                  onClick={() => {
                    handleSubmit(
                      !index && record.is_draft ? "ADD" : "UPDATE",
                      index
                    );
                  }}
                >
                  Save
                </Button>
              ) : (
                <div className="series" style={{ gap: 10 }}>
                  <Button
                    htmlType="button"
                    className={styles.action_btns}
                    style={{ ...(isEditAction && { opacity: 0.4 }) }}
                    onClick={() => {
                      record.edit_mode = true;
                      setIsEditAction(true);

                      updatePricingData();
                    }}
                    disabled={isEditAction}
                  >
                    <img src={EditIcon} alt="edit-" />
                  </Button>
                  <Button
                    htmlType="button"
                    className={styles.action_btns}
                    style={{ ...(isEditAction && { opacity: 0.4 }) }}
                    onClick={() => setToggleDeleteConfirmation(record.id)}
                    disabled={isEditAction}
                  >
                    <img
                      className="clickable"
                      src={DeleteOutlineIcon}
                      alt="delete"
                      width={20}
                      height={20}
                    />
                  </Button>
                </div>
              )}
            </div>
          ),
        };
      },
    },
  ];

  const updatePricingData = () => {
    setProductsList([...productsList]);
  };

  const fetchPricingGroupDetails = async () => {
    if (!pgId) return;
    setPricingDetails((await getPricingGroupDetails(pgId)) || {});
  };

  const fetchProductTelescopePricingList = async (
    isInitCall = false,
    addNewProduct = true
  ) => {
    setLoading(true);
    const res = await getProductsTelescopePricingList(pgId);
    let telescopeToggleCount = 0;

    let updatedDataWithDiscountValue = res?.map((ele) => {
      if (ele.is_enable_telescope_pricing) {
        telescopeToggleCount++;
      }
      return {
        ...ele,
        telescope_pricing: ele.telescope_pricing.map((item, idx) => ({
          ...item,
          discount: handlePricing(ele, idx, "price", item.price).discountValue,
        })),
      };
    });

    constants.raw_products_list = updatedDataWithDiscountValue;
    if (
      isInitCall &&
      telescopeToggleCount &&
      telescopeToggleCount === updatedDataWithDiscountValue.length
    )
      setEnableTelescopeForAll(true);

    setProductsList([
      ...(newProduct?.id && addNewProduct ? [newProduct] : []),
      ...updatedDataWithDiscountValue,
    ]);
    setIsEditAction(false);
    setLoading(false);
  };

  const handleSearch = (v) => {
    const searchedResults = constants.raw_products_list.filter((ele) =>
      ele.name?.toLowerCase()?.includes(v?.toLowerCase())
    );
    setProductsList([
      ...(newProduct?.id ? [newProduct] : []),
      ...searchedResults,
    ]);
  };

  const handleItemDelete = async () => {
    const res = await deleteProductFromPricingGroup(
      pgId,
      toggleDeleteConfirmation
    );
    if (res && res.status === 200) fetchProductTelescopePricingList();
    setToggleDeleteConfirmation(false);
  };

  const handlePricing = (data, idx, type, value) => {
    data.telescope_pricing[idx][type] = value;

    const basePrice = Number(data.price);
    value = Number(value);

    let price, discountValue;
    if (type === "discount") {
      price = formatAfterDecimalPlaces(
        (basePrice + (basePrice * value) / 100).toFixed(4)
      );
      if (!isNaN(value)) {
        data.telescope_pricing[idx]["price"] = price;
      }
    } else if (type === "price") {
      discountValue = formatAfterDecimalPlaces(
        (((value - basePrice) / basePrice) * 100).toFixed(2)
      );
      data.telescope_pricing[idx]["discount"] = discountValue;
    } else {
      data.telescope_pricing[idx]["qty"] = value;
    }
    updatePricingData();
    return { price, discountValue };
  };

  const handleSwitchChange = async (value, items) => {
    if (items.length === 1 && (items[0].is_draft || items[0].edit_mode)) {
      return;
    }
    setLoading(true);
    let index = 0;

    for (const item of items) {
      if (!index && item?.is_draft) {
        index++;
        continue;
      }
      const data = {
        id: item.id,
        product_id: item.product,
        pricing_group: item.pricing_group,
        is_enable_telescope_pricing: value,
        target_price: item?.telescope_pricing?.[0]?.price || item?.price,
        target_mrp_price: item?.target_mrp_price,
        telescope_pricing: formatData(item).telescope_pricing,
      };
      await updateProductTelescopePricing("UPDATE", data);
      index++;
    }
    fetchProductTelescopePricingList();
  };

  const handleSubmit = async (type, itemIndex) => {
    let productDetails = productsList[itemIndex];

    const isValid = validatePricings(productDetails, itemIndex);

    setErrorState({ ...errorState, ...isValid });

    if (isValid[itemIndex]) return;

    productDetails = {
      ...productDetails,
      product_id: productDetails.product,
      pricing_group: pgId,
    };
    const formattedData = formatData(productDetails);

    if (type === "ADD") {
      const res = await updateProductTelescopePricing("ADD", formattedData);
      if (res && res.status === 200) {
        setNewProduct({});
        fetchProductTelescopePricingList(false, false);
      }
    } else {
      const res = await updateProductTelescopePricing("UPDATE", formattedData);
      if (res && res.status === 200) {
        fetchProductTelescopePricingList();
      }
    }
  };

  useEffect(() => {
    constants.raw_products_list = [];
    fetchPricingGroupDetails();
    fetchProductTelescopePricingList(true);
  }, []);

  return (
    <Spin spinning={loading} indicator={<div />}>
      <AdminLayout
        title={
          <div
            className={styles.space_between}
            style={{
              width: "97%",
              paddingBottom: 20,
              borderBottom: "1px solid #fff",
            }}
          >
            <div>
              <div className="series" style={{ gap: 6 }}>
                <img
                  className="clickable"
                  src={ArrowLeft}
                  alt="back"
                  onClick={() => navigate(constants.routes.LIST)}
                />
                {pricingGroupDetails.name}
              </div>
              <div
                style={{
                  color: "#727176",
                  fontWeight: 600,
                  fontSize: 14,
                  paddingLeft: 32,
                }}
              >
                Product Pricing List
              </div>
            </div>
            <div
              className="series"
              style={{ fontSize: 14, color: "#727176", fontWeight: 500 }}
            >
              <div
                className="series"
                style={{
                  gap: 6,
                  paddingRight: 20,
                  borderRight: "1px dashed #fff",
                }}
                onClick={() => {
                  if (pricingGroupDetails?.staff_count)
                    setListViewOptions({
                      images: true,
                      title: "Staffs",
                      api: `${BASE_URL_V2}/organization/${org_id}/pricing-group/${pgId}/mapping/staff/?selected=true`,
                    });
                }}
              >
                <img src={StaffIcon} alt="staff" width={20} height={20} />
                Staffs :{" "}
                <span
                  style={{ fontSize: 18 }}
                  className={
                    pricingGroupDetails?.staff_count ? styles.link : ""
                  }
                >
                  {pricingGroupDetails?.staff_count}
                </span>
              </div>
              <div
                className="series"
                style={{ gap: 6, paddingInline: 20 }}
                onClick={() => {
                  if (pricingGroupDetails?.customer_count)
                    setListViewOptions({
                      images: true,
                      title: "Customers",
                      api: `${BASE_URL_V2}/organization/${org_id}/pricing-group/${pgId}/mapping/customer/?selected=true`,
                    });
                }}
              >
                <img src={Customer} alt="customer" width={20} height={20} />
                Customers :{" "}
                <span
                  style={{ fontSize: 18 }}
                  className={
                    pricingGroupDetails?.customer_count ? styles.link : ""
                  }
                >
                  {pricingGroupDetails?.customer_count}
                </span>
              </div>
              <div
                className="tertiary-button"
                style={{ color: "#727176", borderRadius: 5, gap: 10 }}
                onClick={() =>
                  navigate(`${constants.routes.EDIT_FORM}?pg_id=${pgId}`)
                }
              >
                <img src={EditIcon} alt="edit" /> Edit
              </div>
            </div>
          </div>
        }
        styles={{
          content: {
            padding: "0 24px",
            minHeight: "60vh",
          },
        }}
      >
        <Row justify="space-between" align="middle">
          <Col>
            <SearchInput
              placeholder="Search Product"
              searchValue={handleSearch}
            />
          </Col>
          <Space size="large">
            <Col
              className="tertiary-button"
              style={{ gap: 8, color: "#727176", borderRadius: 4 }}
              onClick={() =>
                navigate(`${constants.routes.BULK_UPLOAD}?id=${pgId}`)
              }
            >
              <img src={bulkUploadIcon} alt="bulk-upload" /> Bulk Upload
            </Col>
            <Col className="series" style={{ gap: 10, color: "#727176" }}>
              Enable Telescopic For All :
              <CustomSwitch
                value={enableTelescopeForAll}
                onChange={(v) => {
                  const data = productsList.map((item) => ({
                    ...item,
                    is_enable_telescope_pricing: v,
                  }));
                  setProductsList(data);
                  setEnableTelescopeForAll(v);
                  handleSwitchChange(v, data);
                }}
                disabled={isEditAction}
              />
            </Col>
            <Col>
              <SelectProductModal
                selectedList={productsList}
                disabled={isEditAction && !newProduct?.id}
                onSave={(v) => {
                  v = {
                    ...v,
                    is_enable_telescope_pricing: enableTelescopeForAll,
                  };
                  setNewProduct(v);
                  setIsEditAction(true);
                  let data = productsList;
                  if (productsList[0]?.is_draft) data[0] = v;
                  else data = [v, ...data];
                  setProductsList([...data]);
                }}
              />
            </Col>
          </Space>
        </Row>
        <br />
        <Table
          loading={loading}
          className="editable pricing-group-table"
          pagination={false}
          columns={columns}
          dataSource={productsList}
          locale={{
            emptyText: (
              <div>
                <img src={noProductIcon} alt="no-Product" />
                <div style={{ fontWeight: 600, color: "#727176" }}>
                  No products
                </div>
              </div>
            ),
          }}
          scroll={{ y: "100%" }}
        />
        <ListViewModal
          listModal={listViewOptions}
          setListModal={setListViewOptions}
        />
        <GeneralModal
          open={toggleDeleteConfirmation}
          title="Remove Product ?"
          info="Are you sure you want to remove this product from the pricing group?"
          cancelText="Cancel"
          onCancel={() => setToggleDeleteConfirmation(false)}
          okText="Delete"
          onOk={handleItemDelete}
        />
      </AdminLayout>
    </Spin>
  );
}

const ProxyColumn = ({ record }) => {
  return (
    <>
      {record.telescope_pricing.length > 1 &&
        Array(record.telescope_pricing.length - 1)
          .fill()
          .map((_, index) => (
            <div
              key={`proxy-${index}`}
              className={styles.nested_cell}
              style={{ background: "#f4f4f4", paddingInline: 10 }}
            >
              <div className={styles.proxy} />
            </div>
          ))}
    </>
  );
};

const PriceInput = ({
  value,
  status,
  suffix,
  onChange,
  readOnly,
  placeholder,
  regexOptions,
  additionalClass = "",
}) => {
  const defaultRegexOptions = {
    decimalPlaces: 4,
    restrictNegative: true,
  };
  regexOptions = { ...defaultRegexOptions, ...regexOptions };

  return (
    <div
      className={styles.nested_cell}
      style={{
        ...(!readOnly && { background: "#fff" }),
        paddingInline: 10,
      }}
    >
      {readOnly ? (
        <div>
          <span style={{ fontWeight: 600, color: "#000" }}>{value} </span>
          {suffix}
        </div>
      ) : (
        <Input
          className={`${styles.price_inputs} ${additionalClass}`}
          {...{ placeholder }}
          {...(suffix && {
            suffix: <span style={{ color: "#727176" }}>{suffix}</span>,
          })}
          status={status}
          onKeyPress={(e) => decimalInputValidation(e, regexOptions)}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      )}
    </div>
  );
};

const formatData = (data) => {
  const updatedList = data.telescope_pricing.map(({ qty, price }) => ({
    qty: Number(qty),
    price: Number(price),
  }));
  data.telescope_pricing = updatedList;
  return data;
};

const validatePricings = (data, index) => {
  let errors = {},
    qtyCounts = {},
    missingFields = [],
    isQtyDuplicate = false;

  if (!Number(data.target_mrp_price)) {
    errors[index] = { ...errors[index], mrp: true };
    missingFields.push("MRP");
  }
  data.telescope_pricing.map((ele, idx) => {
    if (!Number(ele.qty) || qtyCounts[ele.qty]) {
      errors[index] = {
        ...errors[index],
        telescope_pricing: {
          ...errors[index]?.telescope_pricing,
          [idx]: {
            ...(errors?.[index]?.["telescope_pricing"]?.[idx] || {}),
            qty: true,
          },
        },
      };
      if (qtyCounts[ele.qty]) isQtyDuplicate = true;
      missingFields.push("Qty");
    }
    if (!Number(ele.price)) {
      errors[index] = {
        ...errors[index],
        telescope_pricing: {
          ...errors[index]?.telescope_pricing,
          [idx]: {
            ...(errors?.[index]?.["telescope_pricing"]?.[idx] || {}),
            price: true,
          },
        },
      };
      missingFields.push("Price");
    }
    if (!qtyCounts[ele.qty]) qtyCounts = { ...qtyCounts, [ele.qty]: true };
  });

  if (Object.keys(errors).length) {
    if (
      isQtyDuplicate &&
      missingFields.length === 1 &&
      missingFields[0] === "Qty"
    ) {
      notification.warning({
        message: "Qty must be different for each price slab.",
      });
    } else
      notification.warning({
        message: `${missingFields.join(", ")} is required.`,
      });

    return errors;
  }

  return { [index]: false };
};

const resetError = (errorState, index) => {
  if (errorState[index]) delete errorState[index];
};
