import { Modal } from "antd";
import InfiniteScrollWrapper from "../../../components/infinite-scroll-wrapper/infiniteScrollWrapper";
import WrapText from "../../../components/wrapText";
import { Staff as staffIcon } from "../../../assets/dashboardIcon";
import customerIcon from "../../../assets/distributor/customer-img.svg";

const defaultImages = {
  customers: customerIcon,
  staffs: staffIcon,
};

const ListViewModal = ({ listModal, setListModal }) => {
  const { title, api, images = false, shape, optionView } = listModal;

  const onClose = () => {
    setListModal({});
  };

  return (
    <Modal
      key={`open-${title}`}
      open={!!Object.keys(listModal).length}
      onCancel={onClose}
      title={<div style={{ padding: "1em", textAlign: "center" }}>{title}</div>}
      footer={null}
      style={{ fontFamily: "Poppins" }}
      width={600}
      centered
    >
      <InfiniteScrollWrapper apiUrl={api} height="60vh">
        {(item) => {
          return optionView ? (
            optionView(item)
          ) : (
            <div
              style={styles.customer_name_conatiner}
              onClick={() => {
                //   filterService.setFilters({ id: item.id });
                //   setOpenDistributorDrawer(true);
                //   setCustomerDrawerFilters({
                //     activeTab: "insights",
                //   });
              }}
            >
              {images && (
                <img
                  src={
                    item?.logo_image_url ||
                    item?.profile_pic_url ||
                    defaultImages[title?.toLowerCase()]
                  }
                  alt={item.name}
                  style={{
                    ...styles.img_container,
                    borderRadius: shape === "square" ? 4 : "100%",
                  }}
                />
              )}
              <WrapText width={350}>{item.name}</WrapText>
            </div>
          );
        }}
      </InfiniteScrollWrapper>
    </Modal>
  );
};

export default ListViewModal;

const styles = {
  customer_name_conatiner: {
    gap: ".5em",
    display: "flex",
    color: "#727176",
    fontWeight: 600,
    alignItems: "center",
    padding: "0 1.5em 1em",
    borderBottom: "1px solid #FFFFFF",
  },
  img_container: {
    width: 40,
    height: 40,
  },
};
