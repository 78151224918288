import axios from "axios";
import { BASE_URL_V2, org_id } from "../../config";
import SessionExpireError from "../../helpers/sessionExpireError";
import { notification } from "antd";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const callingCities = async () => {
  const url = `${BASE_URL_V2}/organization/${org_id}/dd/cities/`;
  const headers = { Authorization: cookies.get("rupyzToken") };

  return await axios
    .get(url, { headers })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      SessionExpireError(err.response);
      notification.warning({
        message: `${err.response.data.message}`,
      });
    });
};
