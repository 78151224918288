import { useContext } from "react";
import styles from "./styles.module.css";
import { useEffect, useState } from "react";
import Context from "../../../context/Context";
import CustomerFilters from "./customerFilters";
import CustomerDetailCard from "./customerDetailCard";
import { useSelector } from "react-redux";
import SearchInput from "../../search-bar/searchInput";
import noDataIcon from "../../../assets/globle/no-results.svg";
import { customerDistributor as customerDistributorAPI } from "../../../redux/action/customerAction";
import filterService from "../../../services/filter-service";

export default function CustomerDownLevelList({ data }) {
  const state = useSelector((state) => state);
  const { setOpenDistributorDrawer } = useContext(Context);

  const customerLevel = data?.customer_level
    ? "LEVEL-" +
      (Number(data?.customer_level[data?.customer_level?.length - 1]) + 1)
    : "";

  const initialFilters = {
    sort_by: "name",
    sort_order: "ASC",
    staff_id: "",
    customer_type: "",
  };

  const [customerList, setCustomerList] = useState([]);
  const [search, setSearch] = useState();
  const [filters, setFilters] = useState(initialFilters);

  const customerAPI = async () => {
    const filtersValue = {
      customerLevel,
      page: 1,
      staff_id: filters.staff_id || 0,
      customer_parent_id: data?.id,
      search,
      sort_by: filters.sort_by || "name",
      sort_order: filters.sort_order || "ASC",
      customer_typ: filters.customer_typee,
    };
    await customerDistributorAPI(filtersValue)
      .then((response) => {
        setCustomerList(response.data.data.slice(0, 8));
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    data.id && customerAPI();
  }, [data?.id, filters, search]);

  useEffect(() => {
    if (
      state.deleteCustomer.data &&
      !state.deleteCustomer.data.data.error &&
      !state?.deleteCustomer?.data?.params?.check_children
    ) {
      customerAPI();
    }
  }, [state]);

  useEffect(() => {
    if (data?.id) {
      setSearch();
    }
  }, [data?.id]);

  return (
    <div className={styles.flex_col}>
      <SearchInput
        testId="customerDownLevelList-searchInput"
        defaultQuery={false}
        searchValue={(data) =>
          setTimeout(() => {
            setSearch(data);
          }, 500)
        }
        value={search}
      />
      <CustomerFilters
        selectedValue={(data) => {
          setFilters({
            sort_by: data.sortBy,
            sort_order: data.sortOrder,
            staff_id: data.staff_id || "",
            customer_type: data.customerTypeList.join(","),
          });
        }}
      />
      {customerList?.length > 0 ? (
        customerList.map((ele, index) => (
          <>
            {
              <div
                data-testid={`customerDownLevelList-customerDetailCard_${index}`}
              >
                <CustomerDetailCard key={ele?.id} data={ele} />
              </div>
            }
            {index === 7 && (
              <p
                data-testId="customerDownLevelList-view_all_link"
                className={styles.view_all}
                onClick={() => {
                  setOpenDistributorDrawer(false);
                  filterService.setFilters(
                    {
                      customer_parent_id: data?.id,
                      name: data?.name,
                      customer_level: customerLevel,
                      parent_customer_level: data?.customer_level,
                    },
                    { replace: true }
                  );
                }}
              >
                View All
              </p>
            )}
          </>
        ))
      ) : (
        <div style={{ textAlign: "center" }}>
          <img src={noDataIcon} alt="no results" width={300} />
          <p
            data-testId="customerDownLevelList-no_result"
            style={{ color: "#727176" }}
          >
            No Matching Results
          </p>
        </div>
      )}
    </div>
  );
}
