import axios from "axios";
import WrapText from "../wrapText";
import Cookies from "universal-cookie";
import { useEffect, useState } from "react";
import { Checkbox, Modal, Select } from "antd";
import { Cancel } from "../../assets/attendance";
import { Staff as staffIcon } from "../../assets/dashboardIcon";
import customerIcon from "../../assets/distributor/customer-img.svg";

const { Option } = Select;
const cookies = new Cookies();

export const defaultMultiSelectListValue = {
  add_set: [],
  remove_set: [],
  selected_items: [],
};

const constants = {
  initialNextParams: { page_no: 1, selected: "true" },
  defaultIcons: {
    customers: customerIcon,
    staffs: staffIcon,
  },
};

const MultiSelectListView = (props) => {
  return <MultiSelectListInput {...props} key={props.apiUrl} />;
};

const MultiSelectListInput = ({
  apiUrl,
  value,
  onChange,
  optionParams = {
    value: "id",
    label: "name",
  },
  images = false,
  params,
  disabledOptions = { list: [], key: "" },
  selectedListParams,
  action = "ADD",
  formatter = (arr) => arr,
  selectionCount = { visible: false, title: "", initial: 0 },
}) => {
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [search, setSearch] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [timer, setTimer] = useState(null);
  const [loader, setLoader] = useState();
  const [toggleMoreList, setToggleMoreList] = useState(false);
  const [showSelectedOnly, setShowSelectedOnly] = useState(false);
  const [nextParams, setNextParams] = useState({
    ...constants.initialNextParams,
  });
  const [selectedCount, setSelectedCount] = useState(0);

  const handleScrollToBottom = (event) => {
    if (
      (action === "ADD" && showSelectedOnly) ||
      (action === "EDIT" && showSelectedOnly && nextParams.selected === "false")
    )
      return;

    const target = event.target;
    const current = parseInt(target.scrollHeight - target.scrollTop);

    const isBottom =
      current === target.clientHeight || current === target.clientHeight - 1;
    if (isBottom && hasMore) {
      setPage(page + 1);
    }
  };

  const fetchData = async (page, search) => {
    setLoader(true);
    const headers = { Authorization: cookies.get("rupyzToken") };

    const params = search ? { page_no: page, name: search } : nextParams;

    const newDataTemp = await axios.get(apiUrl, { headers, params });
    const data = formatter(newDataTemp.data.data);

    if (
      !search &&
      params?.selected === "true" &&
      data.length &&
      !value?.selected_items?.length &&
      action === "EDIT"
    ) {
      onChange({
        ...value,
        selected_items: updateSelectedList(data, "FILTER"),
      });
    }

    const updateParams = nextParams;
    newDataTemp?.data?.headers?.next_params?.split("&")?.forEach((pair) => {
      const [key, value] = pair.split("=");
      if (key) updateParams[key] = value;
    });
    setNextParams(updateParams);

    if (data.length < 30) {
      if (newDataTemp.data.headers?.next_params) {
        setPage((prev) => prev + 1);
      } else {
        setHasMore(false);
      }
    }
    setLoader(false);
    return data;
  };

  const handleSearch = (v) => {
    setSearchValue(v);
    if (timer) {
      clearTimeout(timer);
    }
    setTimer(
      setTimeout(() => {
        reset();
        setSearch(v);
      }, 600)
    );
  };

  const updateSelectedList = (list, type) => {
    let selectedList = value?.selected_items?.list || [],
      ids = value?.selected_items?.ids || {};

    const itemId = list[0][optionParams.value];
    if (type === "ADD") {
      if (!ids[itemId]) {
        selectedList.push(list[0]);
        ids = { ...ids, [itemId]: true };
      }
    } else if (type === "REMOVE") {
      selectedList = selectedList.filter(
        (ele) => ele[optionParams.value] !== itemId
      );
      ids = { ...ids, [itemId]: false };
    } else {
      list.forEach((item) => {
        const itemId = item[optionParams.value];

        if (isChecked(item) && !ids[itemId]) {
          selectedList.push(item);
          ids = { ...ids, [itemId]: true };
        }
      });
    }
    return { list: selectedList, ids: ids };
  };

  const handleSelection = (e, item) => {
    const { checked } = e.target;
    let updatedSet = { ...value };

    if (checked) {
      if (updatedSet.remove_set.includes(item.id)) {
        const filteredList = updatedSet.remove_set.filter(
          (id) => id !== item.id
        );
        updatedSet.remove_set = filteredList;
      } else updatedSet.add_set.push(item.id);
      updatedSet = {
        ...updatedSet,
        selected_items: updateSelectedList([item], "ADD"),
      };
    } else {
      if (updatedSet.add_set.includes(item.id)) {
        const filteredList = updatedSet.add_set.filter((id) => id !== item.id);
        updatedSet.add_set = filteredList;
      } else updatedSet.remove_set.push(item.id);
      updatedSet = {
        ...updatedSet,
        selected_items: updateSelectedList([item], "REMOVE"),
      };
    }
    onChange && onChange(updatedSet);
    if (selectionCount.visible) {
      const updatedCount =
        (selectionCount.initial || 0) +
        updatedSet.add_set.length -
        updatedSet.remove_set.length;
      setSelectedCount(updatedCount);
    }
  };

  const reset = () => {
    setPage(-1);
    setHasMore(true);
  };

  const isChecked = (item) => {
    let is_checked =
      (item.is_selected &&
        !value?.remove_set.includes(item[optionParams.value])) ||
      value?.add_set.includes(item[optionParams.value]);

    return is_checked;
  };

  useEffect(() => {
    if (page === -1) {
      setPage(1);
      setNextParams({ ...constants.initialNextParams });
    } else {
      fetchData(page, search || "").then((newData) => {
        if (page === 1) setData(newData);
        else setData(data.concat(newData));
      });
    }
  }, [page]);

  useEffect(() => {
    if (selectionCount.visible) {
      const totalCount =
        (selectionCount.initial || 0) +
        value.add_set.length -
        value.remove_set.length;
      setSelectedCount(totalCount);
    }
  }, [selectionCount]);

  return (
    <div>
      <Select
        showSearch
        value={[]}
        style={{ width: "100%" }}
        filterOption={false}
        onSearch={handleSearch}
        searchValue={searchValue}
        onInputKeyDown={(event) => {
          if (event.key === "Enter") {
            handleSearch(event.target.value);
          }
        }}
        allowClear={true}
        onPopupScroll={(e) => {
          if (hasMore && !loader) handleScrollToBottom(e);
        }}
        mode="multiple"
        maxTagCount="responsive"
        placement="bottomLeft"
        onDropdownVisibleChange={(v) => {
          if (!v && search) {
            setSearch("");
            setSearchValue("");
            reset();
          }
        }}
        {...params}
        {...(selectionCount.visible && {
          placeholder: (
            <span style={selectedCount ? { color: "#000" } : {}}>
              {selectedCount
                ? `${selectedCount} ${selectionCount.title}${
                    selectedCount > 1 ? "s" : ""
                  }`
                : params.placeholder}
            </span>
          ),
        })}
      >
        {(data || []).map((item, index) => {
          return (
            <Option
              key={index}
              value={item[optionParams.value]}
              style={{ padding: "0px" }}
              data-testid="multiSelectListView-options"
              data-value={`${item.name.replace(/\s+/g, "_")}`}
            >
              <Checkbox
                data-testid="multiSelectListView-checkbox"
                data-value={`${item.name.replace(/\s+/g, "_")}`}
                checked={isChecked(item)}
                onClick={(e) => {
                  if (
                    !(disabledOptions?.list || []).includes(
                      item[disabledOptions?.key]
                    )
                  ) {
                    handleSelection(e, item);
                  }
                }}
                style={{
                  width: "100%",
                  height: "100%",
                  padding: "10px",
                  ...((disabledOptions?.list || []).includes(
                    item[disabledOptions?.key]
                  )
                    ? {
                        background: "#eee",
                        opacity: 0.5,
                      }
                    : {}),
                }}
              >
                <div className="series" style={{ gap: 6 }}>
                  {images && (
                    <ProfileImage
                      item={item}
                      title={selectedListParams.title}
                    />
                  )}
                  {item[optionParams.label]}
                </div>
              </Checkbox>
            </Option>
          );
        })}
      </Select>
      <SelectedSubList
        {...{
          images,
          optionParams,
          data: value?.selected_items?.list || [],
          disabledOptions,
          handleSelection,
          selectedListParams,
        }}
      />
      {value?.selected_items?.list?.length > 8 && (
        <div
          onClick={() => {
            setToggleMoreList(true);
            setShowSelectedOnly(true);
          }}
          style={styles.view_more_link}
        >
          View More
        </div>
      )}
      <ViewMoreModal
        onClose={() => {
          setToggleMoreList(false);
          setShowSelectedOnly(false);
        }}
        {...{
          images,
          isChecked,
          toggleMoreList,
          handleSelection,
          disabledOptions,
          selectedListParams,
          handleScrollToBottom,
          data: value?.selected_items?.list,
        }}
      />
    </div>
  );
};

export default MultiSelectListView;

const SelectedSubList = ({
  images,
  optionParams,
  data,
  disabledOptions,
  handleSelection,
  selectedListParams,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        gap: 20,
        marginTop: 20,
      }}
    >
      {data.slice(0, 8).map((ele, index) => {
        return (
          <div
            data-testid="MultiSelectListView-selected_item"
            data-value={`${ele.name.replace(/\s+/g, "_")}`}
            key={index}
            style={{
              borderRadius:
                selectedListParams.title.toLowerCase() === "customers" ? 4 : 28,
              border: "1px solid #DDD",
              background: "#FFF",
              padding: "5px 10px",
              display: "flex",
              alignItems: "center",
              fontSize: 12,
              gap: 5,
              ...((disabledOptions?.list || []).includes(
                ele[disabledOptions.key]
              ) && styles.disabled),
            }}
          >
            {images && (
              <ProfileImage
                item={ele}
                title={selectedListParams.title}
                size={24}
              />
            )}
            <WrapText width={100}>{ele[optionParams.label]}</WrapText>
            <RemoveIcon
              item={ele}
              {...{ handleSelection, disabledOptions, index }}
            />
          </div>
        );
      })}
    </div>
  );
};

const ProfileImage = ({ item, title, size = 30 }) => {
  return (
    <img
      src={
        item?.profile_pic_url ||
        item?.logo_image_url ||
        constants.defaultIcons[title.toLowerCase()]
      }
      alt={item.name}
      style={{
        width: size,
        height: size,
        borderRadius: title === "Customers" ? 4 : "100%",
      }}
    />
  );
};

const RemoveIcon = ({ disabledOptions, handleSelection, item, index }) => {
  return (
    <img
      src={Cancel}
      alt="icon"
      onClick={() => {
        if (
          !(disabledOptions?.list || []).includes(item[disabledOptions.key])
        ) {
          handleSelection({ target: { checked: false } }, item);
        }
      }}
      style={{
        ...((disabledOptions?.list || []).includes(item[disabledOptions.key]) &&
          styles.disabled),
      }}
      className="clickable"
    />
  );
};

const ViewMoreModal = ({
  data,
  images,
  onClose,
  isChecked,
  handleSelection,
  disabledOptions,
  selectedListParams,
  handleScrollToBottom,
  toggleMoreList = false,
}) => {
  return (
    <Modal
      open={toggleMoreList}
      onCancel={onClose}
      title={<div style={styles.title}>{selectedListParams.title}</div>}
      footer={[]}
      centered
      className="multiselect_list_view_modal"
    >
      <div
        style={styles.selected_list_container}
        onScroll={handleScrollToBottom}
      >
        {data?.map(
          (item, index) =>
            isChecked(item) && (
              <div
                key={item.id}
                style={styles.modal_list_view}
                className="modal_list"
              >
                <div
                  className="series"
                  style={{
                    gap: 10,
                    ...((disabledOptions?.list || []).includes(
                      item[disabledOptions.key]
                    ) && styles.disabled),
                  }}
                >
                  {images && (
                    <ProfileImage
                      item={item}
                      title={selectedListParams.title}
                      size={40}
                    />
                  )}
                  <WrapText width={450}>{item.name}</WrapText>
                </div>
                <RemoveIcon
                  {...{ item, handleSelection, disabledOptions, index }}
                />
              </div>
            )
        )}
      </div>
    </Modal>
  );
};

const styles = {
  title: {
    fontSize: 18,
    fontWeight: 500,
    textAlign: "center",
    padding: 10,
  },
  selected_list_container: {
    height: 400,
    overflow: "auto",
  },
  selected_list: {
    gap: 10,
    display: "flex",
    alignItems: "center",
    height: 51,
  },
  list_view_img: {
    width: 40,
    height: 40,
  },
  view_more_link: {
    textDecoration: "underline",
    color: "#322E80",
    fontWeight: "600",
    cursor: "pointer",
    marginTop: 5,
    textAlign: "end",
    display: "flex",
    justifySelf: "flex-end",
  },
  modal_list_view: {
    display: "flex",
    alignItems: "center",
    padding: "10px 20px",
    justifyContent: "space-between",
    borderBottom: "1px solid #FFFFFF",
  },
  disabled: {
    opacity: 0.4,
  },
};
