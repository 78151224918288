import axios from "axios";
import { notification } from "antd";
import Cookies from "universal-cookie";
import { BASE_URL_V2, org_id } from "../../config.js";
import SessionExpireError from "../../helpers/sessionExpireError.js";

const cookies = new Cookies();

export const getPricingGroupList = async (params) => {
  const url = `${BASE_URL_V2}/organization/${org_id}/pricing-group/`;
  const headers = { Authorization: cookies.get("rupyzToken") };

  return axios
    .get(url, { headers, params })
    .then((res) => {
      return res.data.data || [];
    })
    .catch((err) => {
      SessionExpireError(err.response);
    });
};

export const updatePricingGroup = async (formValues) => {
  let url = `${BASE_URL_V2}/organization/${org_id}/pricing-group/`;

  if (formValues?.id) url = url + formValues.id + "/";
  const headers = { Authorization: cookies.get("rupyzToken") };
  return axios
    .post(url, formValues, { headers })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      SessionExpireError(err.response);
      notification.warning({
        message: err.response.data.message,
      });
    });
};

export const getPricingGroupDetails = async (id) => {
  const url = `${BASE_URL_V2}/organization/${org_id}/pricing-group/${id}/`;
  const headers = { Authorization: cookies.get("rupyzToken") };

  return axios
    .get(url, { headers })
    .then((res) => {
      return res.data.data || {};
    })
    .catch((err) => {
      SessionExpireError(err.response);
    });
};

export const updateProductTelescopePricing = async (type, data) => {
  let url = `${BASE_URL_V2}/organization/${org_id}/ppgm/${data.pricing_group}/`;
  if (type === "UPDATE") {
    url = url + `update/${data.id}/`;
  }
  const headers = { Authorization: cookies.get("rupyzToken") };

  return axios
    .post(url, data, { headers })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      SessionExpireError(err.response);
      notification.warning({
        message: err.response.data.message,
      });
    });
};

export const getProductsTelescopePricingList = async (id) => {
  const url = `${BASE_URL_V2}/organization/${org_id}/ppgm/${id}/`;
  const headers = { Authorization: cookies.get("rupyzToken") };
  return axios
    .get(url, { headers })
    .then((res) => {
      return res.data.data || [];
    })
    .catch((error) => {
      SessionExpireError(error.response);
    });
};

export const deleteProductFromPricingGroup = async (pgId, productPricingId) => {
  const url = `${BASE_URL_V2}/organization/${org_id}/ppgm/${pgId}/delete/${productPricingId}/`;
  const headers = { Authorization: cookies.get("rupyzToken") };

  return axios
    .post(url, {}, { headers })
    .then((res) => {
      if (res.status === 200)
        notification.success({
          message: res.data.message,
        });
      return res;
    })
    .catch((err) => {
      SessionExpireError(err.response);
      notification.warning({
        message: err.response.data.message,
      });
    });
};

export const deletePricingGroup = async (id) => {
  const url = `${BASE_URL_V2}/organization/${org_id}/pricing-group/${id}/delete/`;
  const headers = { Authorization: cookies.get("rupyzToken") };
  const data = { id, is_forced: true };

  return axios
    .post(url, data, { headers })
    .then((response) => {
      if (response.status === 200) {
        notification.success({
          message: `${response.data.message}`,
        });
      }
      return response;
    })
    .catch((err) => {
      SessionExpireError(err.response);
      notification.warning({
        message: `${err.response.data.message}`,
      });
    });
};
