import { useEffect, useState } from "react";
import styles from "../styles.module.css";
import { useNavigate } from "react-router-dom";
import {
  getPricingGroupDetails,
  updatePricingGroup,
} from "../../../../redux/action/pricingGroupAction";
import { ArrowLeft } from "../../../../assets/globle";
import WrapText from "../../../../components/wrapText";
import { Button, Form, Input, message, notification } from "antd";
import { BASE_URL_V2, org_id } from "../../../../config";
import AdminLayout from "../../../../components/AdminLayout";
import { GeneralModal } from "../../../../components/modals";
import MultiSelectListView, {
  defaultMultiSelectListValue,
} from "../../../../components/selectSearch/MultiSelectListView";

export default function AddEditPricingGroup() {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [details, setDetails] = useState({ customer_count: 0, staff_count: 0 });
  const [existingMappedCustomers, setExistingMappedCustomers] = useState([]);

  const queryParameters = new URLSearchParams(window.location.search);
  const pgId = queryParameters.get("pg_id") || 0;

  const fetchDetails = async () => {
    if (!pgId) return;
    const res = (await getPricingGroupDetails(pgId)) || {};
    form.setFieldsValue({ name: res.name });
    setDetails({
      ...res,
    });
  };

  const handleSubmit = async (v) => {
    if (v?.is_forced) {
      v = { ...v, ...form.getFieldsValue() };
    }
    const res = await updatePricingGroup({ ...v, id: pgId });
    if (res && res.status === 200) {
      const mappedCustomers = res.data.data.conflicting_customer_list || [];
      if (mappedCustomers.length) {
        setExistingMappedCustomers(mappedCustomers);
      } else {
        notification.success({
          message: res.data.message,
        });
        navigate(
          `/web/setting/pricing-group/products?pg_id=${res.data.data.id}`
        );
      }
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  return (
    <div className={styles.add_edit_form_container}>
      <AdminLayout
        title={
          <div className="series" style={{ gap: 8 }}>
            <img
              src={ArrowLeft}
              alt="back"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
            {pgId ? "Edit" : "Add"} Pricing
          </div>
        }
        styles={{
          content: {
            paddingTop: 0,
          },
        }}
      >
        <Form
          form={form}
          layout="vertical"
          requiredMark={(label, info) => (
            <div>
              {label} {info.required && <span style={{ color: "red" }}>*</span>}
            </div>
          )}
          validateMessages={{
            required: "${label} is required.",
          }}
          initialValues={{
            select_staff: defaultMultiSelectListValue,
            select_customer: defaultMultiSelectListValue,
          }}
          onFinish={handleSubmit}
        >
          <Form.Item
            label="Name"
            name="name"
            className={styles.form_item_card}
            rules={[
              { required: true, message: "" },
              {
                validator: (_, v) => {
                  if (v && v.match(/^[a-zA-Z0-9#\/(),.+\-:&% ]*$/))
                    return Promise.resolve();
                  return Promise.reject(
                    new Error("Please enter a valid ${label}")
                  );
                },
              },
            ]}
          >
            <Input
              placeholder="Enter Name"
              data-testid="addPricingGroup-name"
            />
          </Form.Item>
          <Form.Item
            label="Assign Customer"
            name="select_customer"
            className={styles.form_item_card}
          >
            <MultiSelectListView
              apiUrl={`${BASE_URL_V2}/organization/${org_id}/pricing-group/${pgId}/mapping/customer/`}
              params={{
                placeholder: "Assign Customer",
              }}
              selectionCount={{
                visible: true,
                title: "Customer",
                initial: details.customer_count,
              }}
              selectedListParams={{ title: "Customers" }}
              action={pgId ? "EDIT" : "ADD"}
              images={true}
            />
          </Form.Item>
          <Form.Item
            label="Assign Staff"
            name="select_staff"
            className={styles.form_item_card}
          >
            <MultiSelectListView
              apiUrl={`${BASE_URL_V2}/organization/${org_id}/pricing-group/${pgId}/mapping/staff/`}
              params={{
                placeholder: "Assign Staff",
              }}
              selectionCount={{
                visible: true,
                title: "Staff",
                initial: details.staff_count,
              }}
              selectedListParams={{ title: "Staffs" }}
              action={pgId ? "EDIT" : "ADD"}
              images={true}
            />
          </Form.Item>

          <div
            className="series"
            style={{ gap: 10, justifyContent: "flex-end" }}
          >
            <Button
              htmlType="button"
              className="button_secondary"
              style={{ paddingBlock: 0 }}
              onClick={() => navigate(-1)}
              data-testid="addPricingGroup-cancel_button"
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              className="button_primary"
              data-testid="addPricingGroup-save_button"
            >
              Save
            </Button>
          </div>
        </Form>
        <GeneralModal
          open={!!existingMappedCustomers.length}
          title="Confirm Customer Reassignment"
          info={
            <div>
              <div style={{ color: "#727176" }}>
                Following customers are{" "}
                <span style={{ color: "#000" }}>already assigned</span> to
                another pricing group:
              </div>
              <br />
              <div className={styles.existing_mapped_list_container}>
                {existingMappedCustomers.map((ele, index) => (
                  <div key={index} className="series" style={{ gap: 8 }}>
                    <div className={styles.bullet} />
                    <WrapText width={300}>{ele}</WrapText>
                  </div>
                ))}
              </div>
              <br />
              <div style={{ color: "#727176" }}>
                Do you want to assign them to this group?
              </div>
            </div>
          }
          cancelText="Cancel"
          onCancel={() => setExistingMappedCustomers([])}
          okText="Reassign and Proceed"
          onOk={() => handleSubmit({ is_forced: true })}
        />
      </AdminLayout>
    </div>
  );
}
