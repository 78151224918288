import { Button, Modal } from "antd";
import { useEffect, useState } from "react";
import { PlusCircleFilled } from "@ant-design/icons";
import WrapText from "../../../../components/wrapText";
import { BASE_URL_V1, org_id } from "../../../../config";
import { toIndianCurrency } from "../../../../helpers/convertCurrency";
import SearchInput from "../../../../components/search-bar/searchInput";
import defaultProductIcon from "../../../../assets/no-photo-available.gif";
import InfiniteScrollWrapper from "../../../../components/infinite-scroll-wrapper/infiniteScrollWrapper";

export default function SelectProductModal({ selectedList, onSave, disabled }) {
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isDraftPresent, setIsDraftPresent] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [toggleConfirmation, setToggleConfirmation] = useState(false);

  const handleClose = () => {
    setSearchValue("");
    setIsDraftPresent(false);
    setToggleConfirmation(false);
    setOpen(false);
  };

  const handleSelect = (item) => {
    onSave({
      ...item,
      product: item.id,
      target_mrp_price: item.mrp_price,
      telescope_pricing: [
        {
          qty: 1,
          price: item.price,
          discount: 0,
        },
      ],
      is_draft: true,
      edit_mode: true,
    });
    handleClose();
  };

  useEffect(() => {
    if (open) {
      const ids = selectedList.map((ele) => {
        if (ele.is_draft) setIsDraftPresent(true);
        return ele.product;
      });
      setSelectedProducts(ids);
    }
  }, [open]);

  return (
    <div>
      <Button
        size="large"
        htmlType="button"
        className="button_primary"
        icon={<PlusCircleFilled style={{ fontSize: 24 }} />}
        onClick={() => {
          if (disabled) return;
          setOpen(true);
        }}
        style={{ ...(disabled && styles.disabled_btn) }}
      >
        Choose Product
      </Button>
      <Modal
        open={open}
        onCancel={handleClose}
        title={
          <div style={{ paddingBlock: "1em", textAlign: "center" }}>
            Choose Product
          </div>
        }
        footer={[]}
      >
        <div style={{ padding: "1.5em" }}>
          <SearchInput
            placeholder="Search Product"
            value={searchValue}
            searchValue={(v) => setSearchValue(v)}
          />
        </div>
        <div style={{ background: "#FFFFFF" }}>
          <InfiniteScrollWrapper
            key={searchValue}
            apiUrl={`${BASE_URL_V1}/organization/${org_id}/product/es/?is_published=true&name=${searchValue}`}
            height={400}
          >
            {(item, index) => (
              <div
                className="series clickable"
                style={{
                  padding: index ? "0 20px 14px" : "14px 20px",
                  justifyContent: "space-between",
                  borderBottom: "2px solid #eee",
                  ...(selectedProducts.includes(item.id) && {
                    background: "#F4F4F4",
                    marginTop: index ? "-14px" : "",
                    paddingTop: 14,
                    cursor: "not-allowed",
                  }),
                }}
                onClick={() => {
                  if (selectedProducts.includes(item.id)) return;

                  if (isDraftPresent) {
                    setToggleConfirmation(item);
                    return;
                  }
                  handleSelect(item);
                }}
              >
                <div className="series" style={{ gap: 10 }}>
                  <img
                    src={item.display_pic_url || defaultProductIcon}
                    alt={item.name}
                    style={{ width: 40, height: 40, borderRadius: 4 }}
                  />
                  <div>
                    <div style={{ fontWeight: 600, color: "#000" }}>
                      <WrapText width={350}>
                        {item.name.replace(item?.variant_name, "")}
                      </WrapText>
                    </div>
                    <div
                      className="series"
                      style={{ gap: 6, color: "#727176" }}
                    >
                      {toIndianCurrency(item.price, 4)}
                      {item.variant_name && (
                        <>
                          <span
                            style={{
                              width: 3,
                              height: 3,
                              background: "#727176",
                            }}
                          />
                          <WrapText width={250}>{item.variant_name}</WrapText>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {selectedProducts.includes(item.id) && (
                  <div style={styles.added_tag}>Added</div>
                )}
              </div>
            )}
          </InfiniteScrollWrapper>
        </div>
      </Modal>
      <Modal
        centered
        open={!!toggleConfirmation?.id}
        footer={null}
        closable={false}
        title={
          <div style={{ padding: 20 }}>
            <div>
              You're currently editing a product. Adding a new product will
              overwrite the current changes. Do you want to proceed?
            </div>
            <br />
            <div
              className="series"
              style={{ justifyContent: "flex-end", gap: 8 }}
            >
              <Button
                className="button_secondary"
                style={{ paddingBlock: 0 }}
                onClick={() => handleClose()}
              >
                Cancel
              </Button>
              <Button
                className="button_primary"
                onClick={() => handleSelect(toggleConfirmation)}
              >
                Continue
              </Button>
            </div>
          </div>
        }
      />
    </div>
  );
}

const styles = {
  added_tag: {
    fontSize: 12,
    color: "#727176",
    borderRadius: 30,
    background: "#fff",
    padding: "5px 10px",
  },
  disabled_btn: {
    opacity: 0.4,
    cursor: "not-allowed",
  },
};
